// import { overrideConfig } from "netnut-logs/app";
// overrideConfig({ URL: `${(import.meta as any).env.VITE_REACT_APP_API_URL}applog`, AUTH: "" });
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ErrorBoundary } from "react-error-boundary";
import "@/index.css";
import "@/normalize.css";
import App from "@/app";
import store from "@store/store";
import { BrowserRouter } from "react-router-dom";
import PageNotFound from "@screens/errors/404/404.component";

const logError = async (error: Error, info: { componentStack: string }) => {
  // Do something with the error, e.g. log to an external API
  // console.log("err bounsdry", error, info);
  console.error(error);
  console.error(info);
};

const domNode = document.getElementById("root")!;
const root = ReactDOM.createRoot(domNode);

root.render(
  <ErrorBoundary FallbackComponent={PageNotFound} onError={logError}>
    <Provider store={store}>
      <GoogleReCaptchaProvider
        reCaptchaKey="6LeikWggAAAAAIBTEUIVyVB-Zs2DLf7RotzAkBqO"
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: "head", // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GoogleReCaptchaProvider>
      <Toaster />
    </Provider>
  </ErrorBoundary>
);
