import $axios from "@utils/axios";
import { tokenValid } from "@functions/tokenValid";
// import { isCn } from "@utils/general";
import { toast } from "react-hot-toast";

export const login = async (
  setIsLogged: any,
  setIsLoading: any,
  dispatchCustomer: any,
  dispatchProductTypes: any
) => {
  if (
    !tokenValid(localStorage.getItem("redux"), "redux") &&
    !tokenValid(localStorage.getItem("impersonate"), "impersonate")
  ) {
    localStorage.clear();
    setIsLogged(false);
    setIsLoading(false);
  } else {
    try {
      const response = await $axios.get<any>("auth/me");
      const { data } = response;
      // if (![0, 1, 4, 7].includes(data.data.customer_type)) {
      //     alert("You are redirected to the portal");
      //     localStorage.clear();
      //     const extensionWebsite = isCn() ? "cn" : "io";
      //     location.href = `https://reports.netnut.${extensionWebsite}/`;
      // } else {
      dispatchCustomer(data.data);
      const resp = await $axios.get<any>(
        `customers/${data.data.customer_id}/plans/product-types`
      );
      if (!resp.data.success) return toast.error(resp.data.message);
      dispatchProductTypes(resp.data.data);
      setIsLogged(true);
      setIsLoading(false);
      // }
    } catch (e: any) {
      console.error(e);
      toast.error((e?.message as string) || "Error while logging in");
      setIsLogged(false);
      setIsLoading(false);
    }
  }
};
