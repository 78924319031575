import $axios from "../utils/axios";

class PlansService {
  baseUrl: string;

  constructor(custId: number) {
    this.baseUrl = `/customers/${custId}/plans`;
  }

  async getAllPlans() {
    const plans = await $axios.get(`${this.baseUrl}`);
    return plans.data;
  }

  async addPromoCode(website_plan_id: number, code: string) {
    const cust = await $axios.post(`${this.baseUrl}/promo-code`, {
      website_plan_id,
      code,
    });
    return cust.data;
  }
}

export default PlansService;
