import { createSlice } from "@reduxjs/toolkit";
import { ThemeState } from "./theme.interfaces";
const initialState: ThemeState = {
  darkTheme: false,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    logged: (state, action) => {
      return {
        ...state,
        isLogged: action.payload,
      };
    },
    changeTheme: state => {
      return { ...state, darkTheme: !state.darkTheme };
    },
    resetTheme: state => {
      return { ...state, darkTheme: false };
    },
  },
});

export const { changeTheme, resetTheme, logged } = themeSlice.actions;
export default themeSlice.reducer;
