import jwt from 'jsonwebtoken'

export const tokenValid = (redux: any, key: string) => {
    if (!redux) {
        return false;
    }
    try {
        redux = JSON.parse(redux);
    } catch (e) {
        console.error(e);
        return false;
    }
    redux = redux?.account?.token;
    if (redux && jwt.decode(redux)) {
        const expiry = jwt.decode(redux) as { exp: number };
        const now = new Date();
        if (now.getTime() < expiry.exp * 1000) {
            return true;
        } else {
            localStorage.removeItem(key)
                ;
            return false;
        }
    }

    return false;
};