import { Link } from "react-router-dom";
import "./style.css";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
        </div>
        <h2>{t(`We are sorry, Page not found!`)}</h2>
        <p>
          {t(`The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.`)}
        </p>
        <Link to="/">{t(`Back To Homepage`)}</Link>
      </div>
    </div>
  );
};

export default PageNotFound;
