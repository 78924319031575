import { isCn } from "@utils/general";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AccountState,
  UserImageAction,
  UserNameAction,
} from "./account.interfaces";
import photo from "@assets/images/logo.png";

const initialState: AccountState = {
  language: isCn() ? "zh" : "en",
  isLogged: false,
  image: photo,
  plan: {},
  productTypes: undefined,
  promoCodeStep: 0,
  isCheckedServiceOrder: false,
  isAutoRenewal: true,
  transactionId: null,
  serviceOrderId: null,
  serviceOrderDates: {
    start: "",
    end: "",
  },
  customer: {
    customer_id: <number>NaN,
    customer_name: "",
    priority_name: "",
    priority_address: "",
    priority_address_2: "",
    priority_address_3: "",
    customer_dashboard_email: "",
    customer_country_id: NaN,
  },

  activePlan: [
    {
      total_requests: "",
      success_count: "",
      length: "",
      response_countrycode: "",
      CustomersSubscriptionPlan: null,
    },
  ],
  setAllPlans: [
    {
      total_requests: "",
      success_count: "",
      length: "",
      response_countrycode: "",
      CustomersSubscriptionPlan: null,
    },
  ],
};

export const accountSlice: any = createSlice({
  name: "logged",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      return { ...state, language: action.payload };
    },

    setPromoCodeStep: (state, action: PayloadAction<number>) => {
      return { ...state, promoCodeStep: action.payload };
    },

    logged: (state, action) => {
      return {
        ...state,
        isLogged: action.payload,
      };
    },

    changeUsername: (state, action: PayloadAction<UserNameAction>) => {
      return {
        ...state,
        username: action.payload.username,
      };
    },

    setActivePlan: (state, action: PayloadAction<UserNameAction>) => {
      return {
        ...state,
        activePlan: action.payload,
      };
    },

    setAllPlans: (state, action: PayloadAction<UserNameAction>) => {
      return {
        ...state,
        allPlans: action.payload,
      };
    },

    changeImage: (state, action: PayloadAction<UserImageAction>) => {
      return { ...state, image: action.payload.image };
    },

    setToken: (state, action: PayloadAction<string>) => {
      return { ...state, token: action.payload };
    },

    setCustomer: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        customer: action.payload,
      };
    },
    resetCustomer: (state) => {
      if (state.customer) {
        delete state.customer;
      }
    },

    setTransactionId: (state, action: PayloadAction<string>) => {
      return { ...state, transactionId: action.payload };
    },

    setIsAutoRenewal: (state, action: PayloadAction<boolean>) => {
      return { ...state, isAutoRenewal: action.payload };
    },

    setIsCheckedServiceOrder: (state: any, action: PayloadAction<boolean>) => {
      return { ...state, isCheckedServiceOrder: action.payload };
    },

    setDatesServiceOrder: (state: any, action: PayloadAction<boolean>) => {
      return { ...state, serviceOrderDates: action.payload };
    },

    setIdServiceOrder: (state: any, action: PayloadAction<number>) => {
      return { ...state, serviceOrderId: action.payload };
    },

    setProductTypes: (
      state: any,
      action: PayloadAction<{ id: number; name: string }[]>
    ) => {
      return { ...state, productTypes: action.payload };
    },
  },
});

export const {
  setLanguage,
  changeUsername,
  resetCustomer,
  setToken,
  setCustomer,
  setActivePlan,
  logged,
  setIsCheckedServiceOrder,
  setDatesServiceOrder,
  setIdServiceOrder,
  setProductTypes,
  setPromoCodeStep,
  setIsAutoRenewal,
  setAllPlans,
} = accountSlice.actions;

export default accountSlice.reducer;
