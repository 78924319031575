import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LayoutModule } from "./modules.interfaces";

const initialState: LayoutModule[] = [];

export const modulesSlice = createSlice({
  name: "modules",
  initialState,
  reducers: {
    setModules: (_state: LayoutModule[], action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setModules } = modulesSlice.actions;
export default modulesSlice.reducer;
