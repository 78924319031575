import dayjs from "dayjs";

const dateFormat = (date: string, format: string, language: string) => {
  let formats: any = {};
  if (language === "en")
    formats = {
      date: dayjs(date).format("D MMMM YYYY"),
      time: dayjs(date).format("HH:mm"),
      datetime: dayjs(date).format("D MMMM YYYY HH:mm"),
    };
  if (language === "zh")
    formats = {
      date: dayjs(date).format("YYYY MMMM D"),
      time: dayjs(date).format("HH:mm"),
      datetime: dayjs(date).format("YYYY MMMM D HH:mm"),
    };

  return formats[format];
};

export default dateFormat;
// date: dayjs(date).format("D MMMM YYYY"),
// hour: dayjs(date).format("HH:mm"),
// {dayjs(value).format("D MMMM YYYY HH:mm")}
// date: dayjs(date).format("D MMMM YYYY"),
// format("YYYY-MM-DD")
