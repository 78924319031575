import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CustomerLogsInterface,
  customerLogsState,
} from "./customer-logs.interfaces";

const initialState: customerLogsState = {
  logs: [],
};

export const customerLogsSlice = createSlice({
  name: "logged",
  initialState,
  reducers: {
    setCustomerLogs: (
      state,
      action: PayloadAction<CustomerLogsInterface[]>,
    ) => {
      return {
        ...state,
        logs: action.payload,
      };
    },
  },
});

export const { setCustomerLogs } = customerLogsSlice.actions;
export default customerLogsSlice.reducer;
