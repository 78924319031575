import { isCn, loadScript } from "@utils/general";
import TagManager from "react-gtm-module";

export const initGTM = () => {
  let lang = navigator.language || "en";
  try {
    if (localStorage.getItem("redux"))
      lang = JSON.parse(localStorage.getItem("redux") as any).account.language;
  } catch (e) {
    console.error(e);
  }

  if (!isCn()) {
    TagManager.initialize({ gtmId: "GTM-WF7386K" });
    TagManager.dataLayer({ dataLayer: { testData: "blabla" } });
  }
  if (lang.toLocaleLowerCase().includes("ru")) {
    TagManager.initialize({ gtmId: "GTM-W85RTLDQ" });
    TagManager.dataLayer({ dataLayer: { testData: "blabla" } });
  } else {
    TagManager.initialize({ gtmId: "GTM-K6RGJQX" });
    TagManager.dataLayer({ dataLayer: { testData: "blabla" } });
  }
};

export const initTapAffiliate = () => {
  loadScript("https://script.tapfiliate.com/tapfiliate.js", () => {
    (function (t, a: any) {
      (t as any | []).TapfiliateObject = a;
      t[a] =
        t[a] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          ((t[a] as any).q = (t[a] as any).q || []).push(arguments);
        };
    })(window, "tap");

    (window as any).tap("create", "8865-97aa5b", {
      integration: "javascript",
    });
    (window as any).tap("detect");
  });
};

export const loadBaidu = () => {
  if (isCn()) {
    (window as any)._agl = (window as any)._agl || [];
    (window as any)._agl.push(["production", "_f7L2XwGXjyszb4d1e2oxPybgD"]);
    (function () {
      const agl = document.createElement("script");
      agl.type = "text/javascript";
      agl.async = true;
      agl.src =
        "https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD";
      const s = document.getElementsByTagName("script")[0];
      s.parentNode!.insertBefore(agl, s);
    })();
  }
};
export const loadBaidu2 = () => {
  if (isCn()) {
    (function () {
      const agl = document.createElement("script");
      agl.type = "text/javascript";
      agl.async = true;
      agl.src = "https://hm.baidu.com/hm.js?087d2be3a66fa37c42c773efe826881";
      const s = document.getElementsByTagName("script")[0];
      s.parentNode!.insertBefore(agl, s);
    })();
  }
};

export const loadHubspot2 = () => {
  return;
  (function () {
    const agl = document.createElement("script");
    agl.type = "text/javascript";
    agl.id = "hs-script-loader";
    agl.async = true;
    agl.src = "https://js-eu1.hs-scripts.com/26196154.js";
    const s = document.getElementsByTagName("script")[0];
    s.parentNode!.insertBefore(agl, s);
  })();
};

export const loadYandex = () => {
  if (!isCn()) {
    (function (m: any, e: any, t: any, r: any, i: any, k: any, a: any) {
      m[i] =
        m[i] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (m[i].a = m[i].a || []).push(arguments);
        };
      m[i].l = 1 * (new Date() as any);
      (k = e.createElement(t)),
        (a = e.getElementsByTagName(t)[0]),
        (k.async = 1),
        (k.src = r),
        a.parentNode.insertBefore(k, a);
    })(
      window,
      document,
      "script",
      "https://mc.yandex.ru/metrika/tag.js",
      "ym",
      "",
      ""
    );

    (window as any).ym(88190843, "init", {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      ecommerce: "dataLayer",
    });

    const img = new Image();
    const url = "https://mc.yandex.ru/watch/88190843";
    const container = document.getElementById("yandex-div");
    img.onload = function () {
      if (container) {
        container.appendChild(img);
      }
    };
    img.src = url;
  }
};

export const loadHubSpot = () => {
  let observer: any;
  const config = {
    childList: true,
    subtree: true,
  };
  window.addEventListener(
    "load",
    function () {
      let oldHref = document.location.href,
        bodyDOM = document.querySelector("body");
      function checkModifiedBody() {
        const tmp = document.querySelector("body");
        if (tmp != bodyDOM) {
          bodyDOM = tmp;
          observer.observe(bodyDOM, config);
        }
      }
      observer = new MutationObserver(function () {
        if (
          oldHref != document.location.href ||
          (oldHref.includes("register") &&
            document.location.href.includes("register"))
        ) {
          if (
            (oldHref.includes("login") &&
              document.location.href.includes("register")) ||
            (oldHref.includes("register") &&
              document.location.href.includes("register"))
          ) {
            (function () {
              if (document.getElementById("hs-script-loader") === null) {
                const agl = document.createElement("script");
                agl.type = "text/javascript";
                agl.async = true;
                agl.id = "hs-script-loader";
                agl.src = "https://js-eu1.hs-scripts.com/26196154.js";
                const s = document.getElementsByTagName("script")[0];
                (s as any).parentNode.insertBefore(agl, s);
                document.body.append(agl);
              }
            })();
          }
          if (
            oldHref.includes("register") &&
            document.location.href.includes("login")
          ) {
            (function () {
              document.getElementById("hs-script-loader")?.remove();
            })();
          }
          oldHref = document.location.href;
          window.requestAnimationFrame(checkModifiedBody);
        }
      });
      observer.observe(bodyDOM, config);
    },
    false
  );
};
