import { createTheme } from "@mui/material";
import { mainTheme } from "./main-theme";
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: mainTheme.palette.primary.main,
    },
    secondary: {
      main: mainTheme.palette.secondary.main,
    },
    success: {
      main: mainTheme.palette.success.main,
    },
    error: {
      main: mainTheme.palette.error.main,
    },
    background: {
      default: "#495564",
      paper: "#414b57",
    },
    text: {
      secondary: "#fff",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      hint: "darkgray",
    },
  },
  typography: mainTheme.typography,
  components: {
    MuiInput: { defaultProps: { disableUnderline: true } },
    MuiButton: mainTheme?.components?.MuiButton,
    MuiSelect: mainTheme.components.MuiSelect,
    MuiIconButton: mainTheme?.components?.MuiIconButton,
    MuiPopover: mainTheme?.components?.MuiPopover,
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop:
            mainTheme.components.MuiList.styleOverrides.root.paddingTop,
          backgroundColor: "#414b57",
          color: "#fff",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          zIndex: 2,
          marginTop: "7px",
          marginLeft: "7px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingLeft:
            mainTheme.components.MuiInputBase.styleOverrides.input.paddingLeft,
        },
        root: {
          height: mainTheme.components.MuiInputBase.styleOverrides.root.height,
          flexGrow:
            mainTheme.components.MuiInputBase.styleOverrides.root.flexGrow,
          borderRadius:
            mainTheme.components.MuiInputBase.styleOverrides.root.borderRadius,
          // backgroundColor: "rgb(95, 109, 125)",
          "&.Mui-focused": {
            border: "solid 1.3px #755bbe",
            color: "#fff",
            backgroundColor: "rgb(51, 59, 69)",
          },
          "&.Mui-error": {
            opacity: 1,
            border: "solid 1.3px #ed4c5c",
            color: "#ed4c5c",
            backgroundColor: "#ffffff",
          },
          backgroundColor: "rgb(95, 109, 125)",
        },
        multiline: {
          height:
            mainTheme.components.MuiInputBase.styleOverrides.root.multiline
              .height,
          paddingTop:
            mainTheme.components.MuiInputBase.styleOverrides.root.multiline
              .paddingTop,
        },
      },
    },
  },
});
