import $axios from "../utils/axios";

class LogsService {
  baseUrl: string;

  constructor() {
    this.baseUrl = `/audits`;
  }

  async get(): Promise<any> {
    const customer = await $axios.get(`${this.baseUrl}`);
    return customer.data;
  }
}

export default LogsService;
