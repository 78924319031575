import { lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@mui/system";
import { Suspense } from "react";
import { ConfirmProvider } from "material-ui-confirm";

//internal modules
import { selectCustomer } from "@store/account/account.selectors";
import {
  setActivePlan,
  setAllPlans,
  setCustomer,
  setLanguage,
  setProductTypes,
} from "@store/account/account.slice";
import { selectLanguage } from "@store/account/account.selectors";
// import { retry } from "@utils/general";
import { selectTheme } from "@store/theme/theme.selectors";
import { lightTheme, darkTheme } from "./theme";
import { setCustomerLogs } from "@store/customerLogs/customer-logs.slice";
import "./translations/i18n";
import { login } from "@functions/login";
import { getUsage } from "@functions/getUsage";
import {
  initGTM,
  initTapAffiliate,
  loadBaidu,
  loadBaidu2,
  loadYandex,
  loadHubspot2,
} from "@functions/scriptLoader";
import { retry } from "@utils/general";
import i18next from "i18next";

declare global {
  interface Window {
    $crisp: any;
    CRISP_WEBSITE_ID: any;
  }
}

const DefaultLoggedRoutes = lazy(() =>
  retry(() => import("@router/logged-router/logged-router-defaults.component"))
);
const DefaultRootRoutes = lazy(() =>
  retry(() => import("@router/root-router/root-router-defaults.component"))
);

function App() {
  const dispatch = useDispatch();
  //Selectors
  const customer = useSelector(selectCustomer);
  const language = useSelector(selectLanguage);
  const isDarkTheme = useSelector(selectTheme);

  //States
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const urlParams = new URLSearchParams(window.location.search);
  let langParam = urlParams.get("lang");
  if (langParam && ["zh", "en", "ru"].includes(langParam)) {
    langParam = langParam == "ru" ? "ru-RU" : langParam;
    dispatch(setLanguage(langParam));
    i18next.changeLanguage(langParam);
  }

  //Effects
  useEffect(() => {
    login(
      setIsLogged,
      setIsLoading,
      (data: any) => dispatch(setCustomer(data)),
      (data: any) => dispatch(setProductTypes(data))
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      await getUsage(
        isLogged,
        customer.customer_id,
        (data: any) => dispatch(setActivePlan(data)),
        language,
        (data: any) => dispatch(setCustomerLogs(data)),
        (data: any) => dispatch(setAllPlans(data))
      );
    })();
    initGTM();
    initTapAffiliate();
    loadBaidu();
    loadBaidu2();
    loadYandex();
    loadHubspot2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  return (
    !isLoading && (
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <ConfirmProvider>
          <Suspense fallback="...Is loading">
            {isLogged ? <DefaultLoggedRoutes /> : <DefaultRootRoutes />}
          </Suspense>
        </ConfirmProvider>
      </ThemeProvider>
    )
  );
}

export default App;
