import { createTheme } from "@mui/material";
import { mainTheme } from "./main-theme";

export const lightTheme = createTheme({
  variants: {
    MuiInputLabel: [
      {
        props: { variant: "noline" },
        style: {
          zIndex: 2,
          marginTop: "7px",
          marginLeft: "7px",
        },
      },
    ],
  },
  palette: {
    mode: "light",
    primary: {
      main: mainTheme.palette.primary.main,
    },
    secondary: {
      main: mainTheme.palette.secondary.main,
    },
    success: {
      main: mainTheme.palette.success.main,
    },
    error: {
      main: mainTheme.palette.error.main,
    },
    text: {
      primary: "#333333",
      secondary: "#755bbe",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      hint: "darkgray",
    },

    background: {
      default: "#f5faff",
      paper: "#fff",
    },
  },
  typography: mainTheme.typography,
  components: {
    MuiInput: { defaultProps: { disableUnderline: true } },
    MuiButton: mainTheme?.components?.MuiButton,
    MuiPopover: mainTheme?.components?.MuiPopover,
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop:
            mainTheme.components.MuiList.styleOverrides.root.paddingTop,
          backgroundColor: "#fff",
          color: "#333333",
        },
      },
    },
    MuiSelect: mainTheme.components.MuiSelect,
    MuiInputLabel: {
      styleOverrides: {
        root: {
          zIndex: 2,
          // marginTop: "7px",
          // marginLeft: "7px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingLeft:
            mainTheme.components.MuiInputBase.styleOverrides.input.paddingLeft,
        },
        root: {
          fontSize:
            mainTheme.components.MuiInputBase.styleOverrides.root.fontSize,
          height: mainTheme.components.MuiInputBase.styleOverrides.root.height,
          flexGrow:
            mainTheme.components.MuiInputBase.styleOverrides.root.flexGrow,
          borderRadius:
            mainTheme.components.MuiInputBase.styleOverrides.root.borderRadius,
          color: "#333333",
          "::placeholder": {
            color: "#333333",
          },

          "&.Mui-focused": {
            // border: "solid 1.3px #755bbe",
            // color: "#755bbe",
            // backgroundColor: "#fff",
          },
          "&.Mui-error": {
            opacity: 1,
            border: "solid 1.3px #ed4c5c",
            color: "#ed4c5c",
            backgroundColor: "#ffffff",
          },
          backgroundColor: "whitesmoke",
        },
        multiline: {
          height:
            mainTheme.components.MuiInputBase.styleOverrides.root.multiline
              .height,
          paddingTop:
            mainTheme.components.MuiInputBase.styleOverrides.root.multiline
              .paddingTop,
        },
      },
    },
  },
});
