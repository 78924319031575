import { ThemeOptions } from "@mui/material";
import { validateType } from "../functions/type";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    noData: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    noData?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    noData: true;
  }
}

export const mainTheme = validateType<ThemeOptions>()({
  palette: {
    primary: {
      main: "#755bbe",
    },
    secondary: {
      main: "#fda36f",
    },
    success: {
      main: "#99db6e",
    },
    error: {
      main: "#ff0000",
    },
  },
  typography: {
    fontFamily: "Raleway",
    h1: {
      fontWeight: 600,
      fontSize: 32,
    },
    h2: {
      fontWeight: 600,
      fontSize: 24,
    },
    h3: {
      fontWeight: 600,
      fontSize: 20,
    },
    h4: {
      fontSize: 18,
    },
    h5: {
      fontSize: 16,
    },
    h6: {
      fontSize: 14,
    },
    button: {
      textTransform: "none",
      fontSize: 16,
      fontWeight: 600,
    },
    noData: {
      fontSize: 22,
      fontWeight: "normal",
      color: "#333",
      opacity: "0.3",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // color: "#fff",
          minWidth: 147,
          height: 46,
          borderRadius: "10px",
          boxShadow: "none",
        },
        containedSecondary: {
          "&:hover": {
            backgroundColor: "#fda36f",
          },
          "&:Active": {
            backgroundColor: "#df8f61",
          },
        },
        text: {
          color: "darkgray",
          "&:hover": {
            background: "inherit",
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: 28,
          paddingLeft: 15,
          borderRadius: 10,
          paddingTop: 16,
          fontSize: 14,
          ".MuiSelect-select:focus": {
            backgroundColor: "#fff",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        defaultProps: {
          disableRipple: true,
        },
        root: {
          "&.MuiButtonBase-root:hover": {
            bgcolor: "transparent",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: { paddingLeft: 15 },
        root: {
          height: 45,
          flexGrow: 0,
          borderRadius: 10,
          fontSize: 14,
          "&.Mui-focused": {
            // border: "solid 1.3px #755bbe",
          },
          "&.Mui-error": {
            opacity: 1,
            border: "solid 1.3px #ed4c5c",
            color: "#ed4c5c",
            backgroundColor: "#ffffff",
          },
          multiline: {
            height: "auto",
            paddingTop: "5%",
          },
        },
      },
    },
  },
});
