import axios from "axios";
import { isCn } from "./general";
import { toast } from "react-hot-toast";

const abortController = new AbortController();

const $axios = axios.create({
  timeout:110000,
  baseURL: isCn()
    ? "https://api-portal.netnut.cn"
    : import.meta.env.VITE_REACT_APP_API_URL,
  signal: abortController.signal,
});

$axios.interceptors.request.use(
  (config) => {
    config.headers.iscn = isCn();
    const tokenTmp = sessionStorage.getItem("tempToken");

    if (localStorage.getItem("redux")) {
      const token = JSON.parse(localStorage.getItem("redux")!).account.token;
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (tokenTmp) config.headers.Authorization = `Bearer ${tokenTmp}`;

    if (localStorage.getItem("impersonate")) {
      const token = localStorage.getItem("impersonate");
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

const urlWithoutAlert = ["cronjobs/BrainTreeToken.php"];

$axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    console.error(error);

    const isSkip = urlWithoutAlert.filter((u) =>
      originalRequest.url.includes(u)
    );
    if (
      !error.response ||
      typeof error.response === "undefined" ||
      error.response.status > 499 ||
      error.response.status < 100
    ) {
      //
    }
    if (isSkip.length > 0) return Promise.reject(error);
    if (error.response && error.response.data) {
      if (!error.response.data.success) {
        let message;
        if (error.response.data.message) {
          message = error.response.data.message;
        } else {
          message = "Error";
        }
        if (
          [
            "The token expired",
            "premisson denied",
            "A token is required for authentication",
          ].includes(message)
        ) {
          localStorage.clear();
          return (location.href = "/login");
        }

        if (message === "customer blocked") {
          localStorage.clear();
          location.href = "/";
        }
        toast.error(message);
      }
    } else if (
      // eslint-disable-next-line no-dupe-else-if
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      let msg = "";
      error.response.data.errors.map((m: any) => (msg += m.message));

      toast.error(msg);
    } else {
      toast.error("Error");
    }
    if (error && originalRequest) {
      // token expired
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        localStorage.clear();
        window.location.reload();
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default $axios;
