import CustomerService from "@services/customer-service";
import LogsService from "@services/logs-service";
import dateFormat from "./dates";
import PlansService from "@services/plans-service ";

export const getUsage = async (
  isLogged: any,
  customer_id: any,
  dispatchPlan: any,
  language: any,
  dispatchLogs: any,
  dispatchAllPlans: any
) => {
  try {
    if (isLogged) {
      const activePlanRequest = await new CustomerService(
        customer_id
      ).getActivePlans();
      if (activePlanRequest.success) {
        dispatchPlan(activePlanRequest.data);
      }
      const allPlans = await new PlansService(customer_id).getAllPlans();

      if (allPlans.success) {
        dispatchAllPlans(allPlans.data);
      }
      const logs = await new LogsService().get();
      if (logs.success) {
        dispatchLogs(
          logs.data.rows.map(({ action, description, date }: any) => {
            return {
              description,
              action,
              date: dateFormat(date, "date", language),
              hour: dateFormat(date, "time", language),
            };
          })
        );
      }
    }
  } catch (err) {
    console.error(err);
  }
};
