import themeSlice from "./theme/theme.slice";
import accountSlice from "./account/account.slice";
import modulesSlice from "./modules/modules.slice";

import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import customerLogsSlice from "./customerLogs/customer-logs.slice";
import thunk from "redux-thunk";
import persistState from "redux-localstorage";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const rootReducer = combineReducers({
  theme: themeSlice,
  account: accountSlice,
  customerLogs: customerLogsSlice,
  modules: modulesSlice,
});

let composeEnhancers;
if (
  import.meta.env.VITE_REACT_APP_MODE &&
  import.meta.env.VITE_REACT_APP_MODE === "dev"
) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

const store = createStore(
  rootReducer,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  composeEnhancers(applyMiddleware(thunk), persistState())
);
export type AppState = ReturnType<typeof rootReducer>;
export default store;
