import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import { isCn } from "../utils/general";

const getLang = () => {
  if (
    localStorage.getItem("redux") &&
    JSON.parse(localStorage.getItem("redux") as any).account.language
  ) {
    return JSON.parse(localStorage.getItem("redux") as any).account.language;
  } else {
    return isCn() ? "zh" : "en";
  }
};

const locizeOptions = {
  projectId: "1fd980b3-5033-4f5a-a9e6-54923c9ea402",
  apiKey: "b1f4d54e-fbf8-4ba1-a6d5-d87775ae11a8", // YOU should not expose your apps API key to production!!!
  referenceLng: getLang(),
};

i18n.use(LanguageDetector).use(Backend).use(initReactI18next).init({
  debug: false,
  fallbackLng: getLang(),
  backend: locizeOptions,
  saveMissing: true,
  saveMissingTo: "all",
});

i18n.changeLanguage(getLang());
